import React, { useEffect, useState } from "react";
import "./Packages.css";
import { db } from "../../Firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GiStarMedal } from "react-icons/gi";
import { VscDebugBreakpointDataUnverified } from "react-icons/vsc";

const Packages = () => {
  const [entries, setEntries] = useState([]);

  const fetchEntries = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "PACKAGES"), where("STATUS", "==", "ACTIVE"))
      );

      const fetchedEntries = [];
      querySnapshot.forEach((doc) => {
        fetchedEntries.push({ id: doc.id, ...doc.data() });
      });
      fetchedEntries.sort(
        (a, b) => new Date(b.FROM_DATE) - new Date(a.FROM_DATE)
      );
      setEntries(fetchedEntries);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  const handleWhatsApp = (entries) => {
    const message = ` Hello, Help me to know more about : ${entries.PACKAGE} ${entries.HEADING} `;
    window.location.href = `https://wa.me/9846548150?text=${encodeURIComponent(
      message
    )}`;
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options); // 'en-GB' formats as dd/mm/yyyy
  };

  return (

    <div className="packages">
      <h6>Packages</h6>
      <h4> Customized Plan </h4>

      <div className="Package_conatiner">

        <div className="Package_1">
          
          <div className="item_head_wrapper">
            <div className="package_icon_1">
              <GiStarMedal />
            </div>

            <span className="price_div">30 Days
               {/* | 1499/- */}

            </span>
          </div>

          <h3>Silver</h3>

          <h5>Package</h5>

          <ul className="item_content_wrapper">
          <li>Profile setup and optimization</li>
              <li>Competitor Analysis</li>
              <li>Digital Market Plan</li>
              <li>Content Marketing Strategy</li>
              <li>4 Social Media Posters</li>
              <li>2 Reels Editing</li>
              <li>2 Motion Graphic Posters</li>
              <li>Awareness Marketing</li>
              <li>WhatsApp or Instagram Message Campaign</li>
              <li>Channel Creation ÿWhatsApp & InstagramĀ</li>
              <li>
                10K-20K Ad reach in Targeted AudienceÿInstagram+FacebookĀ
              </li>
              <li>Hashtag Marketing</li>
          </ul>

          <button className="package_1_btn">Get qoetes</button>
        </div>

        {/* --------------------------- */}

        <div className="Package_2">
          
          <div className="item_head_wrapper">
            <div className="package_icon_2">
              <GiStarMedal />
            </div>

            <span className="price_div">30 Days
               {/* | 1499/- */}

            </span>
          </div>

          <h3>Gold</h3>

          <h5>Package</h5>

          <ul className="item_content_wrapper_2">
          <li>Profile setup and optimization</li>
          <li>Profile setup and optimization</li>
          <li>Profile setup and optimization</li>
              <li>Competitor Analysis</li>
              <li> Digital Market Plan</li>
              <li>Content Marketing Strategy</li>
              <li> 6 Social Media Posters</li>
              <li> 3 Reels Editing</li>
              <li> 3 Motion Graphic Posters</li>
              <li> 1 Motion Video (30 seconds) </li>
              <li> Awareness Marketing</li>
              <li> WhatsApp or Instagram Message Campaign</li>
              <li> Channel Creation ( WhatsApp & Instagram )</li>
              <li>20K-50K Ad reach in Targeted Audience(Instagram+Facebook)</li>
              <li>Hashtag Marketin</li>
          </ul>

          <button className="package_2_btn">Get qoetes</button>
        </div>

        {/* ------------------------------ */}

        <div className="Package_3">
          
          <div className="item_head_wrapper">
            <div className="package_icon_3">
              <GiStarMedal />
            </div>

            <span className="price_div">30
               Days
               {/* | 1499/- */}

            </span>
          </div>

          <h3>Platinum</h3>

          <h5>Package</h5>

          <ul className="item_content_wrapper_3">
          <li> Profile setup and optimization</li>
              <li> Competitor Analysis</li>
              <li> Digital Market Plan</li>
              <li> Content Marketing Strategy</li>
              <li> Business Website Creation & Managing ÿStatic Website</li>
              <li> 8 Social Media Posters</li>
              <li> 5 Reels Editing</li>
              <li> 4 Motion Graphic Posters</li>
              <li> 3 Motion Video (30 seconds)</li>
              <li>Awareness Marketing</li>
              <li>WhatsApp or Instagram Message Campaign</li>
              <li> Channel Creation (WhatsApp & Instagram)</li>
              <li>
                {" "}
                540K-1 0akh Ad reach in Targeted Audience (Instagram+Facebook)
              </li>
              <li> Hashtag Marketing</li>
          </ul>

          <button className="package_3_btn">Get qoetes</button>
        </div>

      </div>
    </div>
  );
};

export default Packages;
