import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import Spotlight from "../components/Spotlight/Spotlight";
import AboutUs from "../components/AboutUs/AboutUs";
import Packages from "../components/Packages/Packages";
import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";
import OurGuides from "../components/OurGuides/OurGuides";
import "./Home.css"
import { IoIosArrowUp } from "react-icons/io";
import Label from "../components/Label/Label";

const Home = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const galleryRef = useRef(null);
  const packagesRef = useRef(null);
  const contactRef = useRef(null);

  // Define a scroll function
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };


  const [showScrollTop, setShowScrollTop] = useState(false);
  const [borderOffset, setBorderOffset] = useState(283);

  // const handleScroll = () => {
  //   const scrollTop = window.scrollY;
  //   const scrollHeight = document.documentElement.scrollHeight;
  //   const clientHeight = window.innerHeight;

  //   const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 283; // 283 is the circumference of the circle
  //   const borderOffset = 283 - scrollPercentage;

  //   setBorderOffset(borderOffset);

  //   setShowScrollTop(scrollTop > clientHeight);
  // };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // useEffect(() => {
  //   const handleScrollDebounced = () => {
  //     handleScroll();
  //     clearTimeout(window.scrollTimeout);
  //     window.scrollTimeout = setTimeout(() => {
  //       document.querySelector(".scroll_top").classList.remove("active");
  //     }, 100);
  //   };

  //   window.addEventListener("scroll", handleScrollDebounced);
  //   return () => {
  //     window.removeEventListener("scroll", handleScrollDebounced);
  //     clearTimeout(window.scrollTimeout);
  //   };
  // }, []);

  
  return (
    <div>
      <Header />
      <div id="home" ref={homeRef}>
        <Spotlight />
      </div>
      <div id="about" ref={aboutRef}>
        <AboutUs />
      </div>

      <div id="label" ref={packagesRef}>
        <Label />
      </div>

      <div id="packages" ref={packagesRef}>
        <Packages />
      </div>
      {/* <div id="gallery" ref={galleryRef}>
        <Gallery />
      </div> */}
      <div id="OurTourCoordinators" ref={contactRef}>
        <OurGuides />
      </div>
      <Footer
        scrollToSection={scrollToSection}
        refs={{ homeRef, aboutRef, galleryRef, packagesRef, contactRef }}
      />

      {/* progeress bar // */}

{/* <div
        className={`scroll_top ${showScrollTop ? "show" : ""}`}
        onClick={scrollToTop}
      >
        <svg className="progress-circle" viewBox="0 0 100 100">
          <circle
            className="progress-background"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <circle
            className="progress-circle-bar"
            cx="50"
            cy="50"
            r="45"
            style={{ strokeDashoffset: borderOffset }}
          ></circle>
        </svg>
        <IoIosArrowUp /> 
      </div> */}


    </div>
  );
};

export default Home;
