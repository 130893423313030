import React from "react";
import "./Label.css";
import { BiLogoSnapchat } from "react-icons/bi";
import { BsGraphUpArrow } from "react-icons/bs";
import { SiSpringsecurity } from "react-icons/si";
import { MdAddBusiness, MdAutoGraph } from "react-icons/md";
import { GoFileCode } from "react-icons/go";

const Label = () => {
  return (
    <div className="label">
      <div className="label_container">
        <div className="label_item">
        <BsGraphUpArrow />
        <span>Digital Marketing</span>

        </div>
        <div className="label_item">
        <SiSpringsecurity /> <span>security Measures</span>
        </div>
        <div className="label_item">
        <MdAddBusiness />
 <span>Business operation</span>
        </div>
        <div className="label_item">
        <MdAutoGraph /> <span>Optimised Rusult</span>
        </div>
        <div className="label_item">
        <BiLogoSnapchat /> <span>Demo text</span>
        </div>
        <div className="label_item">
        <GoFileCode />
 <span>Web . App developement</span>
        </div>
        <div className="label_item">
        <BiLogoSnapchat /> <span>Demo text</span>
        </div>
      </div>
    </div>
  );
};

export default Label;
