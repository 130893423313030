import React from "react";
import "./OurGuides.css";
import { assets } from "../../assets/assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { LampDemo } from "./LampDemo"; // Adjust the path if necessary
// import { cn } from "../../lib/utils"; // Adjust the relative path based on file location



const OurGuides = () => {







  return (
    <div className="ourGuides">
     
      <h6>Portfolio</h6>

      <h4>Hightlighte with</h4>
      {/* <LampDemo/> */}

      <div className="guide_container">
        <div className="guides">
          <div className="guide_img">
            {/* <img src={assets.guid_5} alt="" /> */}
            <LazyLoadImage
              // src={assets.Sudhakaran}
              effect="blur"
              placeholderSrc={assets.Sudhakaran}
              className="lazy-load-image"
            />
          </div>
          <div className="content_guide">
            <span>...</span>
            {/* <p>Designation</p> */}
          </div>
        </div>

        <div className="guides">
          <div className="guide_img">
            {/* <img src={assets.guid_2} alt="" /> */}
            <LazyLoadImage
              // src={assets.Rajni}
              effect="blur"
              placeholderSrc={assets.Rajni}
              className="lazy-load-image"
            />
          </div>
          <div className="content_guide">
            <span>...</span>
            {/* <p>Designation</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGuides;
