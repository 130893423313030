

import client_baground from "./images/client_img.jpg";

import logo from "../assets/images/Logo.png";

export const assets = {

  logo,




  client_baground
};
