
import React from "react";
import "./Footer.css";
import { FaInstagram, FaFacebook, FaYoutube, FaFacebookF, FaGooglePlusG } from "react-icons/fa";
import { assets } from "../../assets/assets";
import { RiArrowRightSFill, RiYoutubeLine } from "react-icons/ri";
import { GrLinkedinOption } from "react-icons/gr";
import { FiTwitter } from "react-icons/fi";



const Footer = ({ scrollToSection, refs }) => {
  return (
    <div className="footer">

      <footer>

        <h3> Let,s connect! </h3>

        <span> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dignissimos quos assumenda error quis voluptates sapiente adipisci aliquam modi molestiae, eaque quisquam exercitationem ab quidem incidunt unde perferendis repudiandae consectetur quae, </span>

        <div className="button_wrapper_footer">

      

       
              <span className="number_footer"> 9072555429 </span>
     
 
   
        <span className="contact_footer"> demo@gmail.com </span>
    

        </div>

      </footer>

      
    
      <div className="footer_bottom">
        {/* <p>© SAHYADRI TOURS & TRAVELS 2024 . All Rights Reserved.</p>
        <p>
          <span>Developed By</span>
      
          <a
            href="https://www.spinecodes.com/"
            style={{ color: "white", textDecoration: "none" }}
          >
            Spinecodes
          </a>
        </p> */}

        <div className="socail_icons_wrapper">
        <FaFacebookF /> <FaGooglePlusG /> <FaInstagram /> <GrLinkedinOption />
        <FiTwitter />
 <RiYoutubeLine />


 




        </div>
      </div>
    </div>
  );
};

export default Footer;
