import React from "react";
import "./AboutUs.css";
import { assets } from "../../assets/assets";

const AboutUs = () => {
  return (
    <div className="about_us">
      {/* <div className="about_img">
        <img src={assets.About} alt="" />
      </div> */}

      <div className="about_us_continer">

     
      <div className="about_content">
        <h6>About Us</h6>
        <h4>We Provide Best Tour Packages In Your Budget</h4>
        <p>
        we specialize in delivering complete business solutions that are tailored to meet the unique needs of our clients. We understand that every business faces its own set of challenges, and our mission is to identify and solve those problems with innovative, digital strategies. Whether it's overcoming operational inefficiencies, boosting your market presence, or scaling your business to new heights, we are here to provide the answers.
        </p>
      </div>

      </div>
    </div>
  );
};

export default AboutUs;
